.spinner-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 999999;
}

.spinnerContainer {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 9999999;
}
.loader {
    width: 40px;
    height: 40px;
    border: 5px solid var(--color-primary);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
}
.loader:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border: 5px solid var(--color-primary);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    60%,
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes pulse {
    0%,
    60%,
    100% {
        transform: scale(1);
    }
    80% {
        transform: scale(1.2);
    }
}
