.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.outline-none {
    outline: none;
}

.direction-rtl {
    direction: rtl;
}
.direction-ltr {
    direction: ltr;
}

.width-50 {
    width: 50%;
}

.width-100 {
    width: 100%;
}

.height-100 {
    height: 100%;
}

.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-transform-none {
    text-transform: none;
}

.text-underline {
    text-decoration: underline;
}

.text-start {
    text-align: start;
}

.text-center {
    text-align: center;
}

.text-balance {
    text-wrap: balance;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.text-normal {
    font-style: normal;
    font-weight: normal;
}

.word-break-all {
    word-break: break-all;
}

.word-break-word {
    word-break: break-word;
}

.pre-wrap {
    white-space: pre-wrap;
}

.pre-line {
    white-space: pre-line;
}

.break-spaces {
    white-space: break-spaces;
}

.no-wrap {
    white-space: nowrap;
}

.line-through {
    text-decoration: line-through;
}

.visibility-hidden {
    visibility: hidden;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.display-flex {
    display: flex;
}

.display-inherit {
    display: inherit;
}

.text-ellipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.pointer-events-none {
    pointer-events: none;
}

.width-fit-content {
    width: fit-content;
}

.height-fit-content {
    height: fit-content;
}

.remove-margin {
    margin: 0;
}

.user-select-text {
    cursor: text;
    -webkit-user-select: text;
}

.one-lines-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.two-lines-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.three-lines-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
