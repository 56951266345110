.loading .item {
    width: 100px;
    height: var(--font-body-s-font-size);
    cursor: wait;
    background-color: var(--loading-background);
    border-radius: var(--border-primary-rounded-4);
    background-image: linear-gradient(
        90deg,
        var(--loading-background) 0px,
        var(--loading-background-strong) 50%,
        var(--loading-background) 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.loading.animation {
    background-color: var(--loading-background);
    background-image: linear-gradient(
        90deg,
        var(--loading-background) 0px,
        var(--loading-background-strong) 50%,
        var(--loading-background) 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.loading .item.button-medium {
    height: 46px;
}

.loading .item.font-title-size-m {
    height: 49.5px;
}

.loading .item.font-title-size-s {
    height: var(--font-title-s-font-size);
}

.loading .item.font-title-size-xs {
    height: var(--font-title-xs-font-size);
}

.loading .item.font-size-l {
    height: var(--font-body-l-font-size);
}

.loading .item.font-size-xs {
    height: var(--font-body-xs-font-size);
}

.loading .item.font-size-m {
    height: 24px;
}
.loading .item.title-font-size-l {
    height: var(--font-title-l-font-size);
}

.loading .item.title-font-size-xs {
    height: var(--font-title-xs-font-size);
}

.loading .item.title-font-size-m {
    height: var(--font-title-m-font-size);
}

.loading .item.width-small {
    width: 60px;
}

.loading .item.width-large {
    width: 170px;
}
.loading .item.width-full {
    width: 100%;
}

.loading .item.super-large {
    width: 220px;
}

.loading .item.icon {
    --loading-icon-size: var(--icon-size);
    width: var(--loading-icon-size);
    height: var(--loading-icon-size);
    border-radius: 50%;
}

.loading .item.icon.icon-size {
    --loading-icon-size: var(--icon-size);
}

.loading .item.icon.large {
    --loading-icon-size: 44px;
}

.loading .item.icon.super-large {
    --loading-icon-size: 50px;
}

.loading .item.margin-bottom {
    margin-bottom: 6px;
}

@keyframes loading {
    0% {
        background-position: 200% 0; /* Start from the left */
    }
    100% {
        background-position: -200% 0; /* End at the right */
    }
}
