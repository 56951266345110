:root {
    --mdc-filled-button-container-color: var(--color-link);
    --mdc-filled-button-label-text-color: var(--color-white);
    --mdc-filled-button-label-text-weight: 700;

    --mdc-filled-text-field-container-color: var(--color-link-25);
    --mdc-filled-text-field-input-text-color: var(--color-text-primary);
    --mdc-filled-text-field-label-text-color: var(--color-text-primary);
    --mdc-text-button-label-text-color: var(--color-text-primary);
    --mdc-text-button-label-text-weight: 700;
    --mdc-filled-button-label-text-size: var(--font-body-l-font-size);
    --mdc-text-button-label-text-size: var(--font-body-l-font-size);

    --mdc-filled-button-container-height: var(--default-button-height);
    --mdc-text-button-container-height: var(--default-button-height);

    --mdc-filled-button-disabled-container-color: var(--color-link-disabled);
    --mdc-filled-button-disabled-label-text-color: var(--color-white);

    --mat-menu-container-color: var(--color-link-25-grey);

    --mat-standard-button-toggle-shape: 6px;
    --mat-standard-button-toggle-text-color: var(--color-text-primary);
    --mat-standard-button-toggle-background-color: var(--color-link-25-grey);
    --mat-standard-button-toggle-divider-color: var(--color-white);
    .dialog-toggle-button-group {
        --mat-standard-button-toggle-divider-color: var(--color-grey-300);
    }

    --mat-standard-button-toggle-label-text-weight: 700;
    --mat-form-field-container-text-weight: 400;
    --mat-standard-button-toggle-selected-state-background-color: var(--color-link);
    --mat-standard-button-toggle-selected-state-text-color: var(--color-white);

    --mdc-outlined-button-label-text-color: var(--color-link);
    --mdc-outlined-button-outline-color: var(--color-link);
    --mdc-outlined-button-label-text-size: var(--font-body-l-font-size);
    --mdc-outlined-button-label-text-weight: 700;
    --mdc-outlined-button-container-height: var(--default-button-height);
    --mdc-outlined-button-outline-width: 3px;

    --mat-stepper-container-color: var(--color-primary);
    --mat-stepper-header-selected-state-icon-background-color: var(--color-link-25);
    --mat-stepper-header-edit-state-icon-background-color: var(--color-link);

    --mdc-radio-selected-icon-color: var(--color-link);
    --mat-radio-checked-ripple-color: var(--color-link);
    --mdc-radio-selected-pressed-icon-color: var(--color-link);
    --mdc-radio-selected-hover-icon-color: var(--color-link);
    --mdc-radio-selected-focus-icon-color: var(--color-link);

    .mat-accent {
        --mdc-radio-selected-icon-color: var(--color-link);
        --mat-radio-checked-ripple-color: var(--color-link);
        --mdc-radio-selected-pressed-icon-color: var(--color-link);
        --mdc-radio-selected-hover-icon-color: var(--color-link);
        --mdc-radio-selected-focus-icon-color: var(--color-link);

        --mdc-slider-handle-color: var(--color-link);
        --mdc-slider-focus-handle-color: var(--color-link);
        --mdc-slider-hover-handle-color: var(--color-link);
        --mdc-slider-active-track-color: var(--color-link);
        --mdc-slider-inactive-track-color: var(--color-link);
        --mdc-slider-with-tick-marks-inactive-container-color: var(--color-link);
        --mat-slider-ripple-color: var(--color-link);
        --mdc-circular-progress-active-indicator-color: var(--color-link);
    }

    --mat-table-header-container-height: 48px;
    --mat-table-header-headline-color: var(--color-text-primary);
    --mat-table-header-headline-line-height: 24px;
    --mat-table-header-headline-size: var(--font-body-m-font-size);
    --mat-table-header-headline-weight: 700;
    --mat-table-row-item-outline-color: var(--color-grey-200);
    --mat-table-background-color: var(--color-link-25-grey);
    --mat-table-row-item-label-text-line-height: 48px;
    --mat-table-row-item-label-text-size: var(--font-body-m-font-size);
    --mat-sort-arrow-color: var(--color-link);

    --mdc-linear-progress-track-color: var(--color-text-primary);
    --mdc-linear-progress-active-indicator-color: var(--success-color);
    --mdc-linear-progress-track-height: 5px;
    --mdc-linear-progress-active-indicator-height: 5px;

    .dialog-with-border-radius {
        --mdc-dialog-container-shape: 8px;
        --mat-dialog-container-max-width: 548px;
        --mat-dialog-container-min-width: 500px;
        --mat-dialog-with-actions-content-padding: 20px 24px 0 24px;
        --mat-dialog-content-padding: 24px;
    }

    --mat-standard-button-toggle-disabled-selected-state-background-color: var(--color-grey-100);
    --mat-standard-button-toggle-disabled-selected-state-text-color: var(--color-grey-400);
    --mat-standard-button-toggle-label-text-size: var(--font-body-l-font-size);

    --mat-checkbox-label-text-size: var(--font-body-m-font-size);
    --mdc-checkbox-selected-icon-color: var(--color-link);
    --mdc-checkbox-selected-hover-icon-color: var(--color-link);
    --mdc-checkbox-selected-focus-icon-color: var(--color-link);
}

.theme-white {
    --mdc-filled-button-container-color: var(--color-link);
    --mdc-filled-button-label-text-color: var(--color-white);
    --mdc-filled-button-label-text-weight: 700;

    --mdc-filled-text-field-container-color: var(--color-link-25-grey);
    --mdc-filled-text-field-input-text-color: var(--color-text-primary);
    --mdc-filled-text-field-label-text-color: var(--color-text-primary);
    --mdc-text-button-label-text-color: var(--color-text-primary);
    --mdc-text-button-label-text-weight: 700;
    --mdc-filled-button-label-text-size: var(--font-body-l-font-size);
    --mdc-text-button-label-text-size: var(--font-body-l-font-size);

    --mdc-filled-button-container-height: var(--default-button-height);
    --mdc-text-button-container-height: var(--default-button-height);

    --mdc-filled-button-disabled-container-color: var(--color-link-disabled);
    --mdc-filled-button-disabled-label-text-color: var(--color-white);

    --mat-menu-container-color: var(--color-link-25-grey);

    --mat-standard-button-toggle-shape: 6px;
    --mat-standard-button-toggle-text-color: var(--color-text-primary);
    --mat-standard-button-toggle-background-color: var(--color-link-25-grey);
    --mat-standard-button-toggle-divider-color: var(--color-white);
    .dialog-toggle-button-group {
        --mat-standard-button-toggle-divider-color: var(--color-grey-300);
    }
    --mat-standard-button-toggle-label-text-weight: 700;
    --mat-form-field-container-text-weight: 400;
    --mat-standard-button-toggle-selected-state-background-color: var(--color-link);
    --mat-standard-button-toggle-selected-state-text-color: var(--color-white);

    --mdc-outlined-button-label-text-color: var(--color-link);
    --mdc-outlined-button-outline-color: var(--color-link);
    --mdc-outlined-button-label-text-size: var(--font-body-l-font-size);
    --mdc-outlined-button-label-text-weight: 700;
    --mdc-outlined-button-container-height: var(--default-button-height);
    --mdc-outlined-button-outline-width: 3px;

    --mat-stepper-container-color: var(--color-white);
    --mat-stepper-header-selected-state-icon-background-color: var(--color-link);

    --mdc-radio-selected-icon-color: var(--color-link);
    --mat-radio-checked-ripple-color: var(--color-link);
    --mdc-radio-selected-pressed-icon-color: var(--color-link);
    --mdc-radio-selected-hover-icon-color: var(--color-link);
    --mdc-radio-selected-focus-icon-color: var(--color-link);

    .mat-accent {
        --mdc-radio-selected-icon-color: var(--color-link);
        --mat-radio-checked-ripple-color: var(--color-link);
        --mdc-radio-selected-pressed-icon-color: var(--color-link);
        --mdc-radio-selected-hover-icon-color: var(--color-link);
        --mdc-radio-selected-focus-icon-color: var(--color-link);

        --mdc-slider-handle-color: var(--color-link);
        --mdc-slider-focus-handle-color: var(--color-link);
        --mdc-slider-hover-handle-color: var(--color-link);
        --mdc-slider-active-track-color: var(--color-link);
        --mdc-slider-inactive-track-color: var(--color-link);
        --mdc-slider-with-tick-marks-inactive-container-color: var(--color-link);
        --mat-slider-ripple-color: var(--color-link);

        --mdc-circular-progress-active-indicator-color: var(--color-link);
    }

    --mat-table-header-container-height: 48px;
    --mat-table-header-headline-color: var(--color-text-primary);
    --mat-table-header-headline-line-height: 24px;
    --mat-table-header-headline-size: var(--font-body-m-font-size);
    --mat-table-header-headline-weight: 700;
    --mat-table-row-item-outline-color: var(--color-grey-200);
    --mat-table-background-color: var(--color-link-25-grey);
    --mat-table-row-item-label-text-line-height: 48px;
    --mat-table-row-item-label-text-size: var(--font-body-m-font-size);
    --mat-sort-arrow-color: var(--color-link);

    --mdc-linear-progress-track-color: var(--color-text-primary);
    --mdc-linear-progress-active-indicator-color: var(--success-color);
    --mdc-linear-progress-track-height: 5px;
    --mdc-linear-progress-active-indicator-height: 5px;

    .dialog-with-border-radius {
        --mdc-dialog-container-shape: 8px;
        --mat-dialog-container-max-width: 548px;
        --mat-dialog-container-min-width: 500px;
        --mat-dialog-with-actions-content-padding: 20px 24px 0 24px;
        --mat-dialog-content-padding: 24px;
    }

    --mat-standard-button-toggle-disabled-selected-state-background-color: var(--color-grey-100);
    --mat-standard-button-toggle-disabled-selected-state-text-color: var(--color-grey-400);
    --mat-standard-button-toggle-label-text-size: var(--font-body-l-font-size);

    --mat-checkbox-label-text-size: var(--font-body-m-font-size);
    --mdc-checkbox-selected-icon-color: var(--color-link);
    --mdc-checkbox-selected-hover-icon-color: var(--color-link);
    --mdc-checkbox-selected-focus-icon-color: var(--color-link);
}

.mat-mdc-menu-item-text {
    display: flex;
    flex-direction: row;
    align-items: start;
    place-content: center flex-start;
}

.cdk-overlay-container {
    z-index: 900000 !important;
}

.placeholder-value {
    .mat-mdc-select-placeholder {
        color: var(--color-grey-black) !important;
    }
}

.mat-mdc-select-panel {
    padding: 0 !important;
}

.panel-alert-class {
    margin-bottom: calc(var(--safe-area-bottom) + var(--page-margin)) !important;
}

.parcel-mat-label {
    &.required {
        &::after {
            content: '*';
        }
    }
}
